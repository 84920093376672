import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxt) => {
  const app = nuxt.vueApp;
  const router = useRouter();

  Sentry.init({
    app,
    enabled: process.env.NODE_ENV === "production",
    dsn: "https://36a7b9eaa6535111b7f10d49266eed75@o405696.ingest.sentry.io/4506794861133824",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 0.1,
  });
});
