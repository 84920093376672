<template>
  <div
    :class="
      cn(
        'relative z-10 select-none flex [*]:select-none',
        $attrs.class ?? '',
        width === 'full' ? 'w-full' : 'w-auto'
      )
    "
  >
    <button
      v-on="$attrs"
      :disabled="($attrs.disabled as boolean) || loading"
      :class="cn(buttonVariants({ variant, size, width, color: bgColor }))"
    >
      <v-icon v-if="loading" name="pr-spinner" class="mr-2 animate-spin" />
      <span v-if="loading">{{ loadingText }}</span>
      <slot v-else />
    </button>
    <span
      class="rounded-[10px] z-[-1] absolute w-full h-full inset-0 border-2"
      :class="{
        'border-accent': bgColor !== 'white',
        'border-[#A2A2A2]/50': bgColor === 'white',
      }"
    ></span>
  </div>
</template>

<script setup lang="ts">
import { cva, type VariantProps } from "class-variance-authority";
import type { ButtonHTMLAttributes } from "vue";
import { cn } from "~/libs/utils";

const buttonVariants = cva(
  "relative inline-flex items-center justify-center rounded-[10px] text-base font-medium tracking-wide ring-offset-background transition-colors disabled:pointer-events-none  border-accent border-2 touch-manipulation",
  {
    variants: {
      variant: {
        default:
          "translate-y-[-4px] translate-x-[-4px] hover:translate-y-[-3px] hover:translate-x-[-3px] transition-all active:translate-x-0 active:translate-y-0 disabled:translate-y-0 disabled:translate-x-0 disabled:bg-gray-100 disabled:text-gray-400",
        flat: "",
      },
      color: {
        default: "bg-primary hover:bg-primary active:bg-primary/80 text-white ",
        secondary:
          "bg-secondary hover:bg-secondary active:bg-secondary/80 text-white",
        secondaryLight:
          "bg-secondaryLight hover:bg-secondaryLight active:bg-secondaryLight/80 text-accent",
        white: "bg-white text-primary border-[#A2A2A2]/50",
        danger: "bg-white border-red-500 text-red-500",
      },
      size: {
        default: "h-[42px] px-10",
        icon: "h-[38px] px-2",
        sm: "h-[38px] px-6",
        lg: "h-[58px] px-10 text-[20px] font-bold",
      },
      width: {
        default: "w-full",
        full: "w-full",
      },
    },
    defaultVariants: {
      color: "default",
      variant: "default",
      size: "default",
    },
  }
);

interface ButtonProps extends /* @vue-ignore */ ButtonHTMLAttributes {
  loading?: boolean;
  loadingText?: string;
  variant?: VariantProps<typeof buttonVariants>["variant"];
  size?: VariantProps<typeof buttonVariants>["size"];
  width?: VariantProps<typeof buttonVariants>["width"];
  bgColor?: VariantProps<typeof buttonVariants>["color"];
}

withDefaults(defineProps<ButtonProps>(), {
  variant: "default",
  size: "default",
  color: "default",
  type: "button",
  width: "default",
  bgColor: "default",
  loadingText: "Processando",
});
</script>
