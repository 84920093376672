<template>
  <div class="container flex pt-10">
    <div class="flex flex-col mx-auto text-center max-w-[450px]">
      <h1 v-if="error?.statusCode === 404" class="font-serif text-2xl">
        Página não encontrada
      </h1>
      <h1 v-else class="font-serif text-2xl">Oops! Houve um erro.</h1>
      <p v-if="error?.statusCode === 404" class="mb-4">
        A página que você está procurando não foi encontrada.
      </p>
      <p v-else class="mb-4">
        Parece que algo deu errado. Tente recarregar a página ou entre em
        contato com o suporte.
      </p>
      <UiButton v-if="error?.statusCode === 404" @click="() => $router.go(-1)">
        Voltar
      </UiButton>
      <UiButton v-else @click="reload">
        Atualizar página
      </UiButton>
      <a href="/">
        <UiButton size="sm" variant="flat" bg-color="white" class="mt-4">
          Voltar para a página inicial
        </UiButton>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";
import { UiButton } from "~/components/ui";
definePageMeta({
  layout: "empty",
});

const props = defineProps({
  error: Object as () => NuxtError,
});

const reload = () => {
  window.location.reload();
};

useHead({
  title: props.error?.statusCode === 404 ? "Página não encontrada" : "Erro",
});
</script>
